import { Experiment } from "../model/experiment";
import {
  CheckpointNode,
  ExperimentStepNode,
  FinishNode,
  QuestionnaireNode,
  StartNode,
} from "../model/nodes";
import {
  ButtonWidget,
  ConditionalWidget,
  DropdownWidget,
  RichTextWidget,
  StepperWidget,
  TextAreaWidget,
  TextInputWidget,
} from "../model/widgets";

export const ComoPodemosExperiment: Experiment = {
  name: "¿Cómo podemos?",
  description: "",
  nodes: [
    new StartNode("start", { group: "default" }),
    new ExperimentStepNode("organizacion", {
      widgets: [
        new RichTextWidget({
          content: `# ¿Cómo podemos mapear desafíos de la transición?`,
        }),
        new RichTextWidget({
          content: `Para empezar, juntes. Por eso estamos construyendo un mapa para entender mejor en qué anda cada une y como nos conectamos entre quienes ya estamos en esta.`,
        }),
        new DropdownWidget({
          dataKey: "organizacion",
          placeholder: "Elegí una opción",
          text: "¿A qué organización pertenecés?",
          options: [
            { text: "GridX", value: "gridx" },
            { text: "El Gato y La Caja", value: "eglc" },
            { text: "Fundar", value: "fundar" },
            { text: "¿Ahora qué?", value: "ahora-que" },
            { text: "Jóvenes por el clima", value: "joca" },
            { text: "Otra", value: "otra" },
          ],
        }),
        new ConditionalWidget({
          dataKey: "$organizacion",
          condition: "eq",
          value: "otra",
          widget: new TextInputWidget({
            placeholder: "Otra organización",
            dataKey: "otra-organizacion",
            text: "",
          }),
        }),
        new ConditionalWidget({
          dataKey: "$organizacion",
          condition: "neq",
          value: "",
          widget: new ButtonWidget({
            buttonText: "Siguiente",
          }),
        }),
      ],
    }),
    new ExperimentStepNode("instrucciones", {
      widgets: [
        new RichTextWidget({ content: "# Instrucciones para sumarte" }),
        new RichTextWidget({
          content: "Te vamos a mostrar una serie de preguntas de diseño.",
        }),
        new RichTextWidget({
          content:
            "El objetivo es que nos cuentes cuánto esta pregunta representa un desafío en el que tu equipo está trabajando activamente.",
        }),
        new ButtonWidget({
          buttonText: "Siguiente",
        }),
      ],
    }),
    new QuestionnaireNode("como-podemos-questionnaire", {
      questionnaireSlug: "preguntas-como-podemos",
      manipultaion: {
        order: "random",
        steps: 4,
        type: "steps",
      },
      pre: [
        new StepperWidget({}),
        new RichTextWidget({
          content: "# ¿Cómo podemos...?",
        }),
        new RichTextWidget({
          content:
            "Contestá, entre nada y mucho, **cuanto les ocupa** cada desafío.",
        }),
      ],
      post: [
        new ButtonWidget({
          buttonText: "Siguiente",
        }),
      ],
    }),
    new ExperimentStepNode("tu-pregunta", {
      widgets: [
        new RichTextWidget({
          content:
            "# Por último, ¿hay algún  desafío que les ocupe y quieras agregar como pregunta de diseño?",
        }),
        new TextAreaWidget({
          text: "Tu pregunta:",
          placeholder: "¿Cómo podemos...",
          dataKey: "pregunta",
          required: false,
        }),
        new ConditionalWidget({
          condition: "eq",
          dataKey: "$pregunta",
          value: "",
          widget: new ButtonWidget({ buttonText: "Saltar pregunta" }),
        }),
        new ConditionalWidget({
          condition: "neq",
          dataKey: "$pregunta",
          value: "",
          widget: new ButtonWidget({ buttonText: "Siguiente" }),
        }),
      ],
    }),
    new CheckpointNode("checkpoint", {}),
    new ExperimentStepNode("gracias", {
      widgets: [
        new RichTextWidget({ content: "# ¡Gracias por participar!" }),
        new RichTextWidget({
          content:
            "Vamos a estar procesando lo que aprendimos, mapeando interacciones y agregando las preguntas que hayan surgido para la próxima iteración de esta misma iniciativa.",
        }),
        new RichTextWidget({
          content:
            "Esperamos entender cada vez mejor lo que podemos hacer juntes que no podríamos hacer por separado.",
        }),
        new RichTextWidget({ content: "El próximo paso será intentarlo" }),
        new RichTextWidget({ content: "☀️🌱" }),
      ],
    }),
    new FinishNode("finish", {}),
  ],
  slug: "como-podemos",
};
