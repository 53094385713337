import { useExperiment } from "../../contexts/ExperimentContext";
import { flowCurrentStep, nodeSteps } from "../../model/utils";
import { StepperWidget } from "../../model/widgets";

const Stepper = (props: StepperWidget["props"]) => {
  const {
    state: { state }
  } = useExperiment();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "2px",
        marginTop: "16px"
      }}
    >
      {Array.from({ length: nodeSteps(state.mainNode) }).map((_, i) => {
        return (
          <div
            key={i}
            style={{
              borderRadius: "2px",
              height: "3px",
              flex: 1,
              background: i < flowCurrentStep(state) ? "#8cf1cd" : "#d9d9e0"
            }}
          ></div>
        );
      })}
    </div>
  );
};

export default Stepper;
