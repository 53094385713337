import { ResponseWidgetComponent } from "./widgets";

export type QuestionnaireProps = {
  name: string;
  slug: string;
  description: string;
  questions: ResponseWidgetComponent[];
};

export class Questionnaire {
  constructor(public props: QuestionnaireProps) {}
}
