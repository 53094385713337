import { Questionnaire } from "../model/questionnaire";
import { SliderWidget } from "../model/widgets";

const questions = [
  "¿Cómo podemos promover la colaboración entre equipos para que generen proyectos en conjunto en pos de la transición?",
  "¿Cómo podemos transversalizar los reclamos del movimiento ambiental para priorizar los esfuerzos y maximizar las posibilidades de que pasen cosas concretas?",
  "¿Cómo podemos facilitar que personas que activan de forma individual se agrupen y multipliquen su impacto?",
  "¿Cómo podemos posicionar a LATAM como un actor clave en el desafio global de la transición ante organismos e inversores internacionales para utilizar el potencial en talento humano y biodiversidad de la región?",
  "¿Cómo podemos conseguir que organismos e inversores financien proyectos o empresas que estén sumando valor en la transición?",
  "¿Cómo podemos lograr que inversores de riesgo vean a la innovacón para la transición irresistible y así financiar proyectos de impacto exponencial?",
  "¿Cómo podemos lograr que jóvenes puedan tener un trabajo pago de impacto positivo?",
  "¿Cómo podemos acercar a quienes quieran trabajar en la transición a donde encuentren mayor disfrute e impacto?",
  "¿Cómo podemos transformar a una persona a la que no le importa el cambio climático para que sienta la urgencia de accionar sin generarle una angustia paralizante?",
  "¿Cómo podemos generar sensación de pertenencia a un mundo que aún no existe para masificar y acelerar la transición?",
  "¿Cómo podemos contagiar narrativas sobre un futuro deseable con tanta gente como sea posible para acelerar la adopción de productos y servicios positivos?",
  "¿Cómo podemos lograr que una persona motivada por la transición lidere proyectos concretos?",
  "¿Cómo podemos acercar conocimiento académico y herramientas profesionales a los equipos de trabajo para maximizar sus posibilidades de impacto?",
  "¿Cómo podemos utilizar la investigación básica existente para generar soluciones disruptivas?",
  "¿Cómo podemos aumentar la masa crítica de personas en la calle para presionar a la dirigencia política en la búsqueda de soluciones reales?",
  "¿Cómo podemos involucrar en la política a gente jóven para que su perspectiva esté incluida en la toma de decisiones?",
  "¿Cómo podemos generar presión cuando las leyes no se cumplen para garantizar la implementación de las políticas públicas ambientales existentes?",
  "¿Cómo podemos informar a tomadores de decisiones para lograr una estrategia de largo plazo sobre cambio climático en el Estado?",
  "¿Cómo podemos resolver tensiones entre las prioridades de desarrollo y conservación del ambiente en agro e industria para crear estrategias nacionales superadoras de largo plazo?",
  "¿Cómo podemos potenciar y escalar empresas innovadoras para desplazar a las de mayor impacto ambiental negativo?",
  "¿Cómo podemos financiar la reconstrucción de la red ferroviaria argentina para lograr alternativas de movilidad más sustentable?",
  "¿Cómo podemos generar que los habitantes de grandes ciudades se muevan en bicicleta u otros modos de micromovilidad?",
  "¿Cómo podemos facilitar la transición de habitantes de grandes ciudades hacia una alimentación basada en plantas?"
];

const comoPodemosQuestions = questions.map((q, i) => {
  return new SliderWidget({
    text: q,
    dataKey: `como-podemos-${i}`,
    minLabel: "Nada",
    maxLabel: "Mucho"
  });
});

export const comoPodemosQuestionnaire = new Questionnaire({
  name: "como-podemos",
  description: "",
  slug: "preguntas-como-podemos",
  questions: comoPodemosQuestions
});

export const questionnaires = [comoPodemosQuestionnaire];
