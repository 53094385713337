import { useMemo } from "react";
import RenderExperiment from "./components/RenderExperiment";
import { ExperimentContextProvider } from "./contexts/ExperimentContext";
import { questionnaires } from "./data/questionnaires";
import { Experiment } from "./model/experiment";
import { StartNode } from "./model/nodes";
import { QuestionnaireProps } from "./model/questionnaire";
import { experimentQuestionnaires, shuffle } from "./model/utils";

import { ComoPodemosExperiment } from "./data/experiment";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

const ExperimentView = ({
  experiment,
  questionnaires,
}: {
  experiment: Experiment;
  questionnaires: { questionnaire: QuestionnaireProps; order: string[] }[];
}) => {
  return (
    <ExperimentContextProvider
      initialState={{
        experiment,
        index: 0,
        state: {
          type: "in-node",
          mainNode: experiment.nodes[0] as StartNode,
        },
        questionnaires: questionnaires.map((q) => q.questionnaire),
        questionnairesOrders: Object.fromEntries(
          questionnaires.map((q) => [q.questionnaire.slug, q.order])
        ),
        data: {},
      }}
    >
      <RenderExperiment />
    </ExperimentContextProvider>
  );
};

const experiment: Experiment = ComoPodemosExperiment;

export default function App() {
  const qs = useMemo(() => {
    return experimentQuestionnaires(experiment).map((questionnaireNode) => {
      const {
        props: { questionnaireSlug: qSlug },
      } = questionnaireNode;
      const questionnaire = questionnaires.find((q) => q.props.slug === qSlug)!;

      const {
        props: {
          manipultaion: { pickN = questionnaire.props.questions.length },
        },
      } = questionnaireNode;

      const questions = shuffle(questionnaire.props.questions).slice(0, pickN);

      return {
        questionnaire: { ...questionnaire.props, questions },
        order: questions.map((q) => q.props.dataKey),
      };
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ExperimentView experiment={experiment} questionnaires={qs} />
      </div>
    </ThemeProvider>
  );
}
