import ReactMarkdown from "react-markdown";
import { RichTextWidget } from "../../model/widgets";
import Typography from "@mui/material/Typography";
import * as React from "react";

const RichText = React.memo(function Markdown(props: RichTextWidget["props"]) {
  return (
    <ReactMarkdown
      components={{
        h1: ({ children }) => {
          return <Typography variant={"h1"}>{children}</Typography>;
        },
        h2: ({ children }) => {
          return <Typography variant={"h2"}>{children}</Typography>;
        },
        h3: ({ children }) => {
          return (
            <Typography variant={"large"} component={"p"}>
              {children}
            </Typography>
          );
        },
        p: ({ children }) => {
          return (
            <Typography variant={"body"} component={"p"}>
              {children}
            </Typography>
          );
        },
        h4: ({ children }) => {
          return (
            <Typography variant={"small"} component={"p"}>
              {children}
            </Typography>
          );
        },
        h6: ({ children }) => {
          return (
            <Typography variant={"annotation"} component={"p"}>
              {children}
            </Typography>
          );
        },
      }}
    >
      {props.content}
    </ReactMarkdown>
  );
});

export default RichText;
