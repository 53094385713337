import * as Yup from "yup";
import { ExperimentState } from "../contexts/ExperimentContext";
import { questionnaires } from "../data/questionnaires";
import { Experiment } from "./experiment";
import {
  BranchCondition,
  BranchValue,
  ExperimentStepNode,
  NodeComponent,
  QuestionnaireNode,
  SimpleCondition,
} from "./nodes";
import {
  ConditionalWidget,
  ResponseWidgetComponent,
  WidgetComponent,
} from "./widgets";

export type IsNullable<T, K> = undefined extends T ? K : never;
export type NullableKeys<T> = {
  [K in keyof T]-?: IsNullable<T[K], K>;
}[keyof T];
export type NullableAsRequired<T> = Required<Pick<T, NullableKeys<T>>>;

export const isNotUndefined = <T>(x: T | undefined): x is T => x !== undefined;

export const isResponseWidget = (
  widget: WidgetComponent
): widget is ResponseWidgetComponent => {
  return widget.widgetFamily === "response";
};

export const isConditionalWidget = (
  widget: WidgetComponent
): widget is ConditionalWidget => {
  return widget.widgetFamily === "control" && widget.template === "conditional";
};

export function unique<T>(list: T[], id: (x: T) => string) {
  return list.reduce((acc, curr) => {
    return acc.map(id).includes(id(curr)) ? acc : [...acc, curr];
  }, [] as T[]);
}

function getNodeQuestionnaireSlug(
  node: NodeComponent
): QuestionnaireNode | undefined {
  switch (node.nodeType) {
    case "questionnaire": {
      return node;
    }
  }
}

export const experimentQuestionnaires = (experiment: Experiment) => {
  return unique(
    experiment.nodes
      .flatMap(getNodeQuestionnaireSlug)
      .filter((x) => x !== undefined) as QuestionnaireNode[],
    (q) => q.id
  );
};

export function shuffle<T>(original: T[]): T[] {
  const array = [...original];
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const distribute = (total: number, steps: number) => {
  const min = Math.floor(total / steps);
  const rest = total % steps;
  return Array.from({ length: steps }).map((_, i) => {
    return i < rest ? min + 1 : min;
  });
};

const fns: Record<
  SimpleCondition,
  <T extends BranchValue>(a: T, b: T) => boolean
> = {
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  eq: (a, b) => a === b,
  neq: (a, b) => a !== b,
};

export const evalBranchCondition = (condition: BranchCondition) => {
  if (condition.startsWith("length")) {
    const [, cond] = condition.split("-");
    return <T extends BranchValue>(a: T[], b: number) =>
      fns[cond as SimpleCondition]((a || []).length, b);
  } else if (condition === "includes") {
    return <T extends BranchValue>(a: T[], b: T) => a.includes(b);
  } else {
    return fns[condition as SimpleCondition];
  }
};

const getResponseWidgetBaseSchema = (widget: ResponseWidgetComponent) => {
  const { template } = widget;
  switch (template) {
    case "dropdown": {
      const { props } = widget;

      return Yup.string().oneOf(props.options.map(({ value }) => value));
    }
    case "slider": {
      const { props } = widget;

      return Yup.number()
        .integer()
        .min(props.min, "Por favor seleccioná un valor más alto")
        .max(props.max, "Por favor seleccioná un valor más bajo");
    }
    case "text_input": {
      return Yup.string();
    }
    case "text_area": {
      const { props } = widget;

      let base = Yup.string();
      if (props.minLength !== null && props.minLength !== undefined) {
        base = base.min(props.minLength, "Por favor ingresá menos texto");
      }
      if (props.maxLength !== null && props.maxLength !== undefined) {
        base = base.max(props.maxLength, "Por favor ingresá más texto");
      }
      return base;
    }
  }
};

export const getResponseWidgetSchema = (widget: ResponseWidgetComponent) => {
  const baseSchema = getResponseWidgetBaseSchema(widget);
  return widget.props.required
    ? baseSchema.required("Por favor respondé esta pregunta")
    : baseSchema;
};

const getConditionalWidgetSchema = (widget: ConditionalWidget) => {
  const innerWidget = widget.props.widget;
  if (isResponseWidget(innerWidget)) {
    const schema = getResponseWidgetSchema(innerWidget);
    if (
      widget.props.dataKey.startsWith("$") &&
      !widget.props.dataKey.startsWith("$$")
    ) {
      return (schema as Yup.Schema<string | number>).when(
        [widget.props.dataKey.slice(1)],
        {
          is: (value: any) => {
            return evalBranchCondition(widget.props.condition)(
              widget.props.value,
              value
            );
          },
          then: (schema) =>
            innerWidget.props.required
              ? schema.required("Por favor respondé esta pregunta")
              : schema,
          otherwise: (schema) => schema.notRequired(),
        }
      );
    }
  }
};

export const nodeSteps = (node: NodeComponent): number => {
  switch (node.nodeType) {
    case "start":
    case "noop":
    case "finish": {
      return 0;
    }
    case "experiment_step": {
      return 1;
    }
    case "questionnaire": {
      return node.props.manipultaion.type === "single_page"
        ? 1
        : node.props.manipultaion.steps;
    }
    default: {
      return 0;
    }
  }
};

export const flowCurrentStep = (state: ExperimentState): number => {
  switch (state.type) {
    case "in-node": {
      return 1;
    }
    case "in-questionnaire": {
      return 1 + state.questionnaireStep;
    }
  }
};

export const getStepSchema = (stepNode: ExperimentStepNode) => {
  const tuples = stepNode.props.widgets.filter(isResponseWidget).map((w) => {
    return [w.props.dataKey, getResponseWidgetSchema(w)];
  });

  const tuplesFromConditional = stepNode.props.widgets
    .filter(isConditionalWidget)
    .map((w) => {
      const innerWidget = w.props.widget;
      if (isResponseWidget(innerWidget)) {
        const innerWidgetSchema = getConditionalWidgetSchema(w);
        if (innerWidgetSchema !== undefined) {
          return [innerWidget.props.dataKey, innerWidgetSchema];
        }
      }
      return undefined;
    })
    .filter(isNotUndefined);

  const spec = Object.fromEntries([...tuples, ...tuplesFromConditional]);
  if (tuples.length !== 0) {
    return Yup.object(spec);
  } else {
    return Yup.object();
  }
};

export const getWidgetDefault = (widget: ResponseWidgetComponent) => {
  switch (widget.template) {
    case "dropdown": {
      return "";
    }
    case "slider": {
      return undefined;
    }
    case "text_area":
    case "text_input": {
      return "";
    }
  }
};

export const getStepInitialValues = (stepNode: ExperimentStepNode) => {
  const tuples = stepNode.props.widgets.filter(isResponseWidget).map((w) => {
    return [w.props.dataKey, getWidgetDefault(w)];
  });
  return Object.fromEntries(tuples);
};

export const getQuestionnaire = (questionnaireSlug: string) => {
  return questionnaires.find((q) => q.props.slug === questionnaireSlug)!;
};

export const pick = <T extends Record<string, any>, K extends keyof T>(
  keys: K[]
) => {
  return (obj: T) => {
    const result = {} as Pick<T, K>;
    keys.forEach((key) => {
      result[key] = obj[key];
    });
    return result;
  };
};
