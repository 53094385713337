import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { PossibleValue } from "../../contexts/ExperimentContext";
import { DropdownWidget } from "../../model/widgets";

import { styled, Theme, useTheme } from "@mui/material/styles";
import { useMemo } from "react";

interface ContainerProps {
  value: string;
  theme: Theme;
}

const SelectQuestionContainer = styled("div")(
  ({ theme, value }: ContainerProps) => `
    & .MuiFormControl-root {
        margin: 0;
        color: ${value !== "" ? theme.palette.grey[700] : theme.palette.black};
    }
    & .MuiSelect-select {
        color: ${value !== "" ? theme.palette.black : theme.palette.grey[700]};
    }
`
);

const FormControlDecorated = styled(FormControl)(
  ({ theme }) => `
    margin: ${theme.spacing(1)};
    min-width: 120px;
`
);

export default function Dropdown(props: DropdownWidget["props"]) {
  const { setFieldValue, errors, touched, values } =
    useFormikContext<Record<string, PossibleValue>>();

  const theme = useTheme();

  const options = useMemo(() => {
    return props.options.map(({ value, text }) => ({
      value,
      children: text,
    }));
  }, [props.options]);

  return (
    <div style={{ marginBottom: theme.margin.lg }}>
      <SelectQuestionContainer
        theme={theme}
        value={values[props.dataKey] as string}
      >
        <Typography variant="label">{props.text}</Typography>
        <FormControlDecorated fullWidth>
          <InputLabel id="demo-simple-select-label">
            {props.placeholder}
          </InputLabel>
          <Select
            displayEmpty
            value={values[props.dataKey] as string}
            onChange={(event) => {
              const value = event.target.value;
              setFieldValue(props.dataKey, value);
            }}
            label={props.placeholder}
          >
            {options.map(({ value, children }) => (
              <MenuItem
                key={value}
                value={value}
                style={{
                  padding: "12px 16px",
                }}
              >
                {children}
              </MenuItem>
            ))}
          </Select>
        </FormControlDecorated>
      </SelectQuestionContainer>
      {errors[props.dataKey] && touched[props.dataKey] ? (
        <Typography variant="error">{String(errors[props.dataKey])}</Typography>
      ) : null}
    </div>
  );
}
