import {
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Experiment } from "../model/experiment";
import { NodeComponent, QuestionnaireNode } from "../model/nodes";
import { QuestionnaireProps } from "../model/questionnaire";

export interface ExperimentData {
  id?: string;
  experiment: string;
  "experiment-version": string;
  data: any;
  timestamp: number;
  fulldate: string;
}

type InNodeState = {
  type: "in-node";
  mainNode: Exclude<NodeComponent, QuestionnaireNode>;
};

type InQuestionnaireState = {
  type: "in-questionnaire";
  mainNode: QuestionnaireNode;
  questionnaireStep: number;
};

export type ExperimentState = InNodeState | InQuestionnaireState;

type ExperimentContextData = {
  experiment: Experiment;
  state: ExperimentState;
  index: number;
  data: ExperimentAnswerData;
  extra?: {
    hasResults?: boolean;
  };
  questionnaires: QuestionnaireProps[];
  questionnairesOrders: Record<string, string[]>;
};

export type ExperimentAnswerData = JSONObject;

export type BaseValue = string | number | boolean;
export type PossibleValue = BaseValue | BaseValue[];

type JSONObject = { [x: string]: JSONValue };
type JSONValue = string | number | boolean | JSONObject | JSONValue[];

/* <Actions> */

type NextNode = {
  type: "NEXT_NODE";
};

type SetData = {
  type: "SET_DATA";
  data: ExperimentAnswerData;
};

type SetExtra = {
  type: "SET_EXTRA";
  extra: ExperimentContextData["extra"];
};

type ClearData = {
  type: "CLEAR_DATA";
  data?: ExperimentAnswerData;
};

type GoToNode = {
  type: "GO_TO_NODE";
  nodeId: string;
};

type ReducerAction = NextNode | SetData | GoToNode | ClearData | SetExtra;

/* </Actions> */

function initialStateForNode(
  node: NodeComponent,
  data: ExperimentAnswerData
): ExperimentState {
  switch (node.nodeType) {
    case "questionnaire": {
      return {
        type: "in-questionnaire",
        mainNode: node,
        questionnaireStep: 0,
      };
    }
    default: {
      return {
        type: "in-node",
        mainNode: node,
      };
    }
  }
}

function nextState(
  state: ExperimentState,
  nodes: NodeComponent[],
  index: number,
  data: ExperimentAnswerData
): {
  state: ExperimentState;
  index: number;
  exitPath?: boolean;
  data?: ExperimentAnswerData;
} {
  const nextOrExit = () => {
    if (index + 1 < nodes.length) {
      return {
        state: initialStateForNode(nodes[index + 1], data),
        index: index + 1,
      };
    } else {
      return {
        state,
        index,
        exitPath: true,
      };
    }
  };

  switch (state.type) {
    case "in-node": {
      return nextOrExit();
    }
    case "in-questionnaire": {
      if (state.mainNode.props.manipultaion.type === "single_page") {
        return nextOrExit();
      } else {
        const steps = state.mainNode.props.manipultaion.steps;
        const currStep = state.questionnaireStep;
        if (currStep + 1 < steps) {
          return {
            state: {
              type: "in-questionnaire",
              mainNode: state.mainNode,
              questionnaireStep: currStep + 1,
            },
            index,
          };
        } else {
          return nextOrExit();
        }
      }
    }
  }
  return { state, index };
}

const newData = (
  state: ExperimentState,
  currentData: ExperimentAnswerData,
  data: ExperimentAnswerData,
  reducerState: ExperimentContextData
): JSONObject => {
  switch (state.type) {
    case "in-node":
      return { ...currentData, ...data };
    case "in-questionnaire": {
      const qSlug = state.mainNode.props.questionnaireSlug;

      return {
        ...currentData,
        [qSlug]: {
          order: reducerState.questionnairesOrders[qSlug],
          ...((currentData[qSlug] as JSONObject) || {}),
          ...data,
        },
      };
    }
  }
};

function reducer(
  state: ExperimentContextData,
  action: ReducerAction
): ExperimentContextData {
  switch (action.type) {
    case "NEXT_NODE": {
      const next = nextState(
        state.state,
        state.experiment.nodes,
        state.index,
        state.data
      );
      return {
        ...state,
        state: next.state,
        index: next.index,
      };
    }
    case "GO_TO_NODE": {
      const nodeIndex = state.experiment.nodes.findIndex(
        (node) => node.id === action.nodeId
      );
      return {
        ...state,
        state: initialStateForNode(
          state.experiment.nodes[nodeIndex],
          state.data
        ),
        index: nodeIndex,
      };
    }
    case "SET_DATA": {
      return {
        ...state,
        data: newData(state.state, state.data, action.data, state),
      };
    }
    case "CLEAR_DATA": {
      const { data = {} } = action;
      return {
        ...state,
        data,
      };
    }
    case "SET_EXTRA": {
      return {
        ...state,
        extra: { ...state.extra, ...action.extra },
      };
    }
  }
  return state;
}

const currentNode = (state: ExperimentState): NodeComponent => {
  switch (state.type) {
    case "in-node":
    case "in-questionnaire":
      return state.mainNode;
  }
};

export const ExperimentContext = createContext({
  state: {} as ExperimentContextData,
  dispatch: (value: ReducerAction) => {},
  utils: {
    nextNode: () => {},
    goToNode: (nodeId: string) => {},
    setData: (data: ExperimentAnswerData) => {},
    clearData: (data?: ExperimentAnswerData) => {},
  },
});

export function ExperimentContextProvider(props: {
  children: ReactChild;
  initialState: ExperimentContextData;
}) {
  const [state, dispatch] = useReducer(reducer, props.initialState);

  const nextNode = useCallback(() => {
    dispatch({
      type: "NEXT_NODE",
    });
  }, [dispatch]);

  const goToNode = useCallback(
    (nodeId: string) => {
      dispatch({
        type: "GO_TO_NODE",
        nodeId,
      });
    },
    [dispatch]
  );

  const setData = useCallback(
    (data: SetData["data"]) => {
      dispatch({
        type: "SET_DATA",
        data,
      });
    },
    [dispatch]
  );

  const clearData = useCallback(
    (data: ClearData["data"]) => {
      dispatch({
        type: "CLEAR_DATA",
        data,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const lowLevelNode = currentNode(state.state);
    switch (lowLevelNode.nodeType) {
      case "start":
      case "noop": {
        nextNode();
        break;
      }
      case "checkpoint": {
        fetch(`https://datastore.elgatoylacaja.com/payloads`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              ...state.data,
              timestamp: new Date().getTime(),
            },
            key: "clima-mapa",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            nextNode();
          })
          .catch((e) => {
            console.log(e);
            console.log(state.data);
            // alert("hubo un error");
            nextNode();
          });
        break;
      }
      default:
        break;
    }
  }, [nextNode, state.state, state.data, goToNode]);

  return (
    <ExperimentContext.Provider
      value={{
        state,
        dispatch,
        utils: {
          nextNode,
          goToNode,
          setData,
          clearData,
        },
      }}
    >
      {props.children}
    </ExperimentContext.Provider>
  );
}

export function useExperiment() {
  const { state, utils } = useContext(ExperimentContext);

  return {
    state,
    utils,
  };
}
