import { TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { PossibleValue } from "../../contexts/ExperimentContext";
import { TextInputWidget } from "../../model/widgets";

const TextInput = (props: TextInputWidget["props"]) => {
  const {
    setFieldTouched,
    errors,
    touched,
    handleChange,
    submitCount,
    values,
  } = useFormikContext<Record<string, PossibleValue>>();

  return (
    <div>
      <Typography variant="label">{props.text}</Typography>
      <TextField
        name={props.dataKey}
        placeholder={props.placeholder}
        value={(values[props.dataKey] || "") as string}
        onChange={(e) => {
          setFieldTouched(props.dataKey, true);
          handleChange(e);
        }}
        type="text"
        style={{
          width: "100%",
        }}
      />
      {errors[props.dataKey] && (touched[props.dataKey] || submitCount > 0) ? (
        <Typography variant="error">{String(errors[props.dataKey])}</Typography>
      ) : null}
    </div>
  );
};

export default TextInput;
