import { NullableAsRequired } from './utils'
import { WidgetComponent } from './widgets'

type NodeFamily = 'study' | 'core' | 'control' | 'view'
export abstract class ExperimentNode<U> {
  abstract readonly nodeFamily: NodeFamily
  abstract readonly nodeType: U
}

type NavConfig = { navConfig?: 'left' | 'center' | 'with-link' }

function NodeFamilyBuilder<NF extends NodeFamily>(nodeFamily: NF) {
  return function <T>() {
    return function <U extends string>(
      nodeType: U,
      ...classDefaultProps: {} extends NullableAsRequired<T>
        ? [undefined?]
        : [NullableAsRequired<T>]
    ) {
      return class extends ExperimentNode<U> {
        nodeFamily = nodeFamily
        nodeType = nodeType
        public props: Required<T> & NavConfig
        public id: string
        constructor(id: string, props: T & NavConfig) {
          super()
          this.id = id
          this.props = { ...classDefaultProps[0], ...props } as Required<T>
        }
      }
    }
  }
}

export const ViewNode = NodeFamilyBuilder('view')
export const StudyNode = NodeFamilyBuilder('study')
export const CoreNode = NodeFamilyBuilder('core')
export const ControlNode = NodeFamilyBuilder('control')

export class ExperimentStepNode extends StudyNode<{
  widgets: WidgetComponent[]
  validateStep?: boolean
}>()('experiment_step', { validateStep: false }) {}

type QuestionnareManipulation =
  | {
      type: 'steps'
      order: 'random'
      steps: number
      pickN?: number
    }
  | {
      type: 'single_page'
      order: 'random',
      pickN?: number
    }

export class QuestionnaireNode extends StudyNode<{
  questionnaireSlug: string
  manipultaion: QuestionnareManipulation
  pre?: WidgetComponent[]
  post?: WidgetComponent[]
}>()('questionnaire', { pre: [], post: [] }) {}

export class StartNode extends CoreNode<{ group: string }>()('start') {}
export class FinishNode extends CoreNode()('finish') {}
export class NoOpNode extends CoreNode()('noop') {}
export class CheckpointNode extends CoreNode()('checkpoint') {}

export type SimpleCondition = 'lt' | 'gt' | 'lte' | 'gte' | 'eq' | 'neq'
export type BranchCondition =
  | SimpleCondition
  | 'includes'
  | `length-${SimpleCondition}`

export type BranchValue = string | number | boolean

export type NodeComponent =
  | StartNode
  | FinishNode
  | NoOpNode
  | CheckpointNode
  | ExperimentStepNode
  | QuestionnaireNode

