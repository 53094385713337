import { QuestionnaireProps } from "../model/questionnaire";
import { ExperimentState, useExperiment } from "../contexts/ExperimentContext";
import { ExperimentStepNode } from "../model/nodes";
import { distribute } from "../model/utils";
import RenderNode from "./RenderNode";

const RenderExperimentState = (props: {
  state: ExperimentState;
  questionnaires: QuestionnaireProps[];
}) => {
  const { state, questionnaires } = props;
  switch (state.type) {
    case "in-node": {
      return <RenderNode node={state.mainNode} />;
    }
    case "in-questionnaire": {
      const questionnaire = questionnaires.find(
        (q) => q.slug === state.mainNode.props.questionnaireSlug
      )!;
      if (state.mainNode.props.manipultaion.type === "single_page") {
        return (
          <RenderNode
            node={
              new ExperimentStepNode(state.mainNode.id, {
                validateStep: true,
                widgets: [
                  ...state.mainNode.props.pre,
                  ...questionnaire.questions,
                  ...state.mainNode.props.post
                ]
              })
            }
          />
        );
      } else {
        const questionsByStep = distribute(
          questionnaire.questions.length,
          state.mainNode.props.manipultaion.steps
        );
        const sliceStart = questionsByStep
          .slice(0, state.questionnaireStep)
          .reduce((acum, curr) => acum + curr, 0);
        const sliceEnd = sliceStart + questionsByStep[state.questionnaireStep];
        return (
          <RenderNode
            key={`${state.mainNode.id}-${state.questionnaireStep}`}
            node={
              new ExperimentStepNode(
                `${state.mainNode.id}-${state.questionnaireStep}`,
                {
                  validateStep: true,
                  widgets: [
                    ...state.mainNode.props.pre,
                    ...questionnaire.questions.slice(sliceStart, sliceEnd),
                    ...state.mainNode.props.post
                  ]
                }
              )
            }
          />
        );
      }
    }
    default:
      break;
  }
  return <div></div>;
};

const RenderExperiment = () => {
  const {
    state: { state, questionnaires }
  } = useExperiment();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "600px",
        minHeight: "var(--app-height)"
      }}
    >
      <RenderExperimentState state={state} questionnaires={questionnaires} />
    </div>
  );
};

export default RenderExperiment;
