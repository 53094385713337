import { styled, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { PossibleValue } from "../../contexts/ExperimentContext";
import { TextAreaWidget } from "../../model/widgets";
import RichText from "./RichText";

const TextAreaContainer = styled("div")(({ theme }) => ({
  "& textarea": {
    ...theme.typography.body,
    marginBottom: 0,
    color: theme.palette.grey["700"],
    "&::placeholder": {
      color: theme.palette.grey["600"],
    },
  },
}));

const TextArea = (props: TextAreaWidget["props"]) => {
  const {
    setFieldTouched,
    errors,
    values,
    touched,
    handleChange,
    submitCount,
  } = useFormikContext<Record<string, PossibleValue>>();

  return (
    <TextAreaContainer>
      <Typography variant="label">{props.text}</Typography>
      <TextField
        name={props.dataKey}
        multiline
        InputProps={{ disableUnderline: true }}
        placeholder={props.placeholder}
        value={values[props.dataKey] || ""}
        onChange={(e) => {
          setFieldTouched(props.dataKey, true);
          handleChange(e);
        }}
        style={{ width: "100%" }}
      />
      {errors[props.dataKey] && (touched[props.dataKey] || submitCount > 0) ? (
        <div className={"error"}>
          <RichText content={errors[props.dataKey] || ""} />
        </div>
      ) : null}
    </TextAreaContainer>
  );
};

export default TextArea;
