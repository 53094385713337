import { Typography } from "@mui/material";
import { Formik } from "formik";
import { useExperiment } from "../contexts/ExperimentContext";
import {
  ExperimentStepNode,
  NodeComponent,
  QuestionnaireNode,
} from "../model/nodes";
import {
  evalBranchCondition,
  getStepInitialValues,
  getStepSchema,
  isConditionalWidget,
  isNotUndefined,
  isResponseWidget,
  pick,
} from "../model/utils";
import RenderWidget from "./RenderWidget";
import StepContainer from "./StepContainer";

const ExperimentStep = (props: { node: ExperimentStepNode }) => {
  const { node } = props;
  const {
    props: { widgets },
  } = node;

  const initialValues = getStepInitialValues(node);

  const {
    utils: { nextNode, setData },
  } = useExperiment();

  const validationSchema = getStepSchema(node);

  return (
    <StepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          window.scrollTo(0, 0);

          const keys = widgets
            .map((widget) => {
              if (isResponseWidget(widget)) {
                return widget.props.dataKey;
              }
              if (isConditionalWidget(widget)) {
                if (isResponseWidget(widget.props.widget)) {
                  const isShown = evalBranchCondition(widget.props.condition)(
                    values[widget.props.dataKey.slice(1)],
                    widget.props.value
                  );

                  if (isShown) {
                    return widget.props.widget.props.dataKey;
                  }
                }
              }

              return undefined;
            })
            .filter(isNotUndefined);

          setData(pick(keys)(values));
          nextNode();
        }}
      >
        {({ errors, handleSubmit, submitCount, values }) => {
          return (
            <>
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {widgets
                  .filter((w) => w.template !== "button")
                  .map((widget, i) => {
                    return <RenderWidget widget={widget} key={i} />;
                  })}

                {node.props.validateStep ? (
                  Object.keys(errors).length !== 0 && submitCount > 0 ? (
                    <div style={{ marginTop: "24px" }}>
                      <Typography variant="error">
                        Quedaron algunas preguntas sin responder. Respondelas
                        para continuar.
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )
                ) : null}
                {widgets
                  .filter((w) => w.template === "button")
                  .map((widget, i) => {
                    return <RenderWidget widget={widget} key={i} />;
                  })}
              </form>
            </>
          );
        }}
      </Formik>
    </StepContainer>
  );
};

const RenderNode = (props: {
  node: Exclude<NodeComponent, QuestionnaireNode>;
}) => {
  switch (props.node.nodeType) {
    case "experiment_step": {
      return <ExperimentStep key={props.node.id} node={props.node} />;
    }
    default: {
      break;
    }
  }

  return (
    <StepContainer>
      <span>{/* RenderNode {props.node.nodeType} {props.node.id} */}</span>
    </StepContainer>
  );
};

export default RenderNode;
