import { Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { SliderWidget } from "../../../model/widgets";
import SliderBar from "./SliderBar";

const Grid = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.margin.xs,
  userSelect: "none",
}));

export default function Slider(props: SliderWidget["props"]) {
  const { setFieldValue, setFieldTouched, errors, touched, values } =
    useFormikContext<Record<string, number>>();

  const theme = useTheme();

  return (
    <div style={{ marginBottom: theme.margin.lg }}>
      <Typography style={{ marginBottom: theme.margin.md }}>
        {props.text}
      </Typography>

      <Grid>
        <Typography
          variant="annotation"
          style={{ margin: 0, maxWidth: "40%", textAlign: "left" }}
        >
          {props.minLabel}
        </Typography>
        <Typography
          variant="annotation"
          style={{ margin: 0, maxWidth: "40%", textAlign: "right" }}
        >
          {props.maxLabel}
        </Typography>
      </Grid>
      <SliderBar
        min={props.min}
        max={props.max}
        value={values[props.dataKey]}
        onChange={(value) => {
          setFieldTouched(props.dataKey, true);
          setFieldValue(props.dataKey, value);
        }}
      />
      {errors[props.dataKey] && touched[props.dataKey] ? (
        <Typography variant="error">{String(errors[props.dataKey])}</Typography>
      ) : null}
    </div>
  );
}
