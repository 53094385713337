import SliderMui from "@mui/material/Slider";
import { styled, Theme } from "@mui/material/styles";

interface Props {
  min?: number;
  max?: number;
  step?: number;
  marks?: boolean;
  value?: number;
  onChange?: (value: number) => void;
}

export interface SliderBarComponentProps {
  theme?: Theme;
  color?: string;
  thumbVisible?: boolean;
}

export const SliderBarComponent = styled("div")(
  ({ theme, color, thumbVisible }: SliderBarComponentProps) => `
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    paddingTop: ${theme ? theme.margin.md : 16};

    & .MuiSlider-thumb {
        height: 24px !important;
        width: 24px !important;
        background: ${theme ? "#ffffff" : "#ffffff"};
        border: 3px solid ${theme ? "#1d1d1d" : "#000000"};
        opacity: ${thumbVisible ? "1" : "0"};
        box-shadow: none !important;
        transition: none !important;
    }
    
    & .MuiSlider-thumb:hover {
        background: ${theme ? "#c9c9c9" : "#ffffff"};
    }
    
    & .MuiSlider-root span:nth-of-type(1) {
        background: ${
          color ? color : theme ? theme.palette.lightGrey : "#D9D9D9"
        };
        height: 3px !important;
        opacity: 1;
    }
`
);

interface CircleProps {
  theme?: Theme;
  color?: string;
}

const Circle = styled("div")(({ theme, color }: CircleProps) => ({
  width: 10,
  height: 10,
  background: color ? color : theme ? theme.palette.lightGrey : "#D9D9D9",
  borderRadius: "100%",
}));

export default function SliderBar({
  min = 0,
  max = 100,
  step = 1,
  marks = false,
  value,
  onChange,
}: Props) {
  return (
    <SliderBarComponent thumbVisible={value !== undefined}>
      <Circle />
      <SliderMui
        min={min}
        max={max}
        step={step}
        value={value}
        marks={marks}
        onChange={(_, newVal) => {
          if (typeof newVal === "number" && onChange) {
            onChange(newVal);
          }
        }}
        track={false}
      />
      <Circle />
    </SliderBarComponent>
  );
}
