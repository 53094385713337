import { useFormikContext } from "formik";
import {
  Button,
  Dropdown,
  RichText,
  Slider,
  Stepper,
  TextArea,
  TextInput,
} from "../components/widgets";
import { PossibleValue, useExperiment } from "../contexts/ExperimentContext";
import { evalBranchCondition } from "../model/utils";
import { WidgetComponent } from "../model/widgets";



const RenderWidget = (props: { widget: WidgetComponent }) => {
  const { widget } = props;

  const {
    state: { data },
  } = useExperiment();

  const { values } = useFormikContext<Record<string, PossibleValue>>();

  switch (widget.template) {
    case "rich_text":
      return <RichText {...widget.props} />;
    case "text_input":
      return <TextInput {...widget.props} />;
    case "button":
      return <Button {...widget.props} />;
    case "dropdown":
      return <Dropdown {...widget.props} />;
    case "slider":
      return <Slider {...widget.props} />;
    case "conditional": {
      const conditionKey = widget.props.dataKey;
      let dataValue;

      if (conditionKey.startsWith("$$")) {
        dataValue = data[conditionKey.slice(2)];
      } else if (conditionKey.startsWith("$")) {
        dataValue = values[conditionKey.slice(1)];
      }

      if (dataValue !== undefined) {
        const satisfies = evalBranchCondition(widget.props.condition)(
          // @ts-ignore
          dataValue,
          widget.props.value
        );

        if (satisfies) {
          return <RenderWidget widget={widget.props.widget} />;
        }
      }
      return null;
    }
    case "text_area": {
      return <TextArea {...widget.props} />;
    }
    case "stepper": {
      return <Stepper />;
    }
    default:
      return <div>{/* {widget.widgetFamily} - {widget.template} */}</div>;
  }
};

export default RenderWidget;
