import { ButtonWidget } from "../../model/widgets";
import { useExperiment } from "../../contexts/ExperimentContext";
import { useFormikContext } from "formik";
import { styled, Theme } from "@mui/material";

type Variant = "default" | "secondary" | "text";

interface Props {
  children?: React.ReactNode;
  variant?: Variant;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  icon?: boolean;
}

interface ButtonProps {
  theme?: Theme;
  variant: Variant;
}

const styles = ({ palette, typography: { fontFamily } }: Theme) => ({
  default: {
    fontFamily,
    color: "#1d1d1d",
    background: "transparent",
    border: "1px solid #1d1d1d",
    padding: "20px 64px",
    borderRadius: 99,
    display: "block",
    width: "fit-content",
    "&:hover, &.hover": {
      transition: "all 0.3s ease",
      background:
        "linear-gradient(95.03deg, rgba(140, 241, 205, 0.1) 6.54%, rgba(109, 163, 137, 0.1) 97.98%)",
      color: "#1d1d1d",
    },
    "&:focus, &.focus": {
      transition: "all 0.3s ease",
      background: "#1d1d1d",
      color: palette.grey[50],
      "& svg path": {
        stroke: palette.grey[50],
      },
    },
  },
  secondary: {
    fontFamily,
    color: "#1d1d1d",
    background: "transparent",
    border: "none",
    padding: "16px 32px",
    borderRadius: 99,
    display: "block",
    width: "fit-content",
    "&:hover, &.hover": {
      transition: "all 0.3s ease",
      background:
        "linear-gradient(95.46deg, rgba(var(--rgb-primary), 0.1) 7.61%, rgba(var(--rgb-primary-light), 0.1) 95.01%)",
      color: "#1d1d1d",
    },
  },
  text: {
    fontFamily,
    color: "#1d1d1d",
    background: "transparent",
    padding: 0,
    border: "none",
    borderBottom: "2px solid transparent",
    display: "inline",
    borderRadius: 0,
    width: "fit-content",
    "&:hover, &.hover": {
      color: "#1d1d1d",
      transition: "all 0.3s ease",
      borderBottom: "2px solid #1d1d1d",
    },
  },
});

const ButtonComponent = styled("button")(({ theme, variant }: ButtonProps) => ({
  ...(theme ? styles(theme)[variant] : []),
  transition: "all 0.3s ease",
  cursor: "pointer",
  fontWeight: 400,
  fontSize: 16,
  "&:disabled": {
    opacity: 0.4,
  },
  margin: "0 auto",
  "& .icon": {
    marginLeft: theme?.margin.md,
    position: "relative",
    top: 1,
    "& path": {
      stroke: "#1d1d1d",
    },
  },
}));

function MUIButton({
  children,
  variant = "default",
  className = "",
  type = "button",
  disabled = false,
  icon = false,
  onClick,
}: Props) {
  return (
    <ButtonComponent
      type={type}
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {icon && (
        <svg
          className="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="16"
          viewBox="0 0 26 16"
          fill="none"
        >
          <path
            d="M0.000518799 8.01074H24.1202"
            strokeWidth="1.36735"
            strokeMiterlimit="10"
          />
          <path
            d="M16.588 15.5427L24.12 8.01077L16.588 0.457031"
            strokeWidth="1.36735"
            strokeMiterlimit="10"
          />
        </svg>
      )}
    </ButtonComponent>
  );
}

export default function Button(props: ButtonWidget["props"]) {
  const {
    utils: { goToNode },
  } = useExperiment();

  const { isValid, submitCount } = useFormikContext();

  const disabled = props.disabled || (!isValid && submitCount > 0);

  return (
    <>
      {props.alignBottom && (
        <div
          style={{
            height: "24px !important",
            minHeight: "24px",
            width: "100%",
            marginTop: "auto",
          }}
        ></div>
      )}
      {props.behaivor === "go_to" ? (
        <MUIButton
          type="button"
          variant={props.variant}
          disabled={disabled}
          onClick={() => {
            goToNode(props.nodeId);
          }}
        >
          {props.buttonText}
        </MUIButton>
      ) : null}

      {props.behaivor === "next_node" ? (
        <MUIButton
          variant={props.variant}
          icon={props.withIcon}
          type="submit"
          disabled={disabled}
        >
          {props.buttonText}
        </MUIButton>
      ) : null}
    </>
  );
}
