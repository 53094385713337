import { NullableAsRequired } from './utils'

export abstract class Widget<U> {
  abstract widgetFamily: 'layout' | 'content' | 'response' | 'control'
  abstract template: U
}

function ControlWidget<T>() {
  return function <U extends string>(
    template: U,
    ...classDefaultProps: {} extends NullableAsRequired<T>
      ? [undefined?]
      : [NullableAsRequired<T>]
  ) {
    return class extends Widget<U> {
      widgetFamily = 'control' as const
      template = template
      public props: Required<T>
      constructor(props: T) {
        super()
        this.props = { ...classDefaultProps[0], ...props } as Required<T>
      }
    }
  }
}

function LayoutWidget<T>() {
  return function <U extends string>(
    template: U,
    ...classDefaultProps: {} extends NullableAsRequired<T>
      ? [undefined?]
      : [NullableAsRequired<T>]
  ) {
    return class extends Widget<U> {
      widgetFamily = 'layout' as const
      template = template
      public props: Required<T>
      constructor(props: T) {
        super()
        this.props = { ...classDefaultProps[0], ...props } as Required<T>
      }
    }
  }
}

function ContentWidget<T>() {
  return function <U extends string>(
    template: U,
    ...classDefaultProps: {} extends NullableAsRequired<T>
      ? [undefined?]
      : [NullableAsRequired<T>]
  ) {
    return class extends Widget<U> {
      widgetFamily = 'content' as const
      template = template
      public props: Required<T>
      constructor(props: T) {
        super()
        this.props = { ...classDefaultProps[0], ...props } as Required<T>
        return this
      }
    }
  }
}

interface ResponseWidgetProps {
  dataKey: string
  embedd?: boolean
  required?: boolean
  errorMessage?: string
}

function ResponseWidget<T>(
  defaultProps: NullableAsRequired<ResponseWidgetProps> = {
    embedd: true,
    required: true,
    errorMessage: 'default_error_message',
  }
) {
  return function <U extends string>(
    template: U,
    ...classDefaultProps: {} extends NullableAsRequired<T>
      ? [undefined?]
      : [NullableAsRequired<T>]
  ) {
    return class extends Widget<U> {
      widgetFamily = 'response' as const
      template = template
      public props: Required<T & ResponseWidgetProps>
      constructor(props: T & ResponseWidgetProps) {
        super()
        this.props = {
          ...defaultProps,
          ...classDefaultProps[0],
          ...props,
        } as Required<T & ResponseWidgetProps>
        return this
      }
    }
  }
}

type ButtonBaseConfig = {
  buttonText?: string
  disabled?: boolean
  alignBottom?: boolean
  variant?: 'default' | 'secondary' | 'text'
  withIcon?: boolean
}

type ButtonNext = { behaivor?: 'next_node' }
type ButtonGoTo = {
  behaivor?: 'go_to'
  nodeId: string
}

type ButtonConfig = (ButtonNext | ButtonGoTo) & ButtonBaseConfig

export class ButtonWidget extends LayoutWidget<ButtonConfig>()('button', {
  buttonText: 'next',
  behaivor: 'next_node',
  alignBottom: true,
  disabled: false,
  variant: 'default',
  withIcon: false,
}) {}

export class RichTextWidget extends ContentWidget<{ content: string }>()(
  'rich_text'
) {}

export class TextAreaWidget extends ResponseWidget<{
  text: string
  placeholder?: string
  minLength?: number | null
  maxLength?: number | null
}>()('text_area', {
  placeholder: '',
  minLength: null,
  maxLength: null,
}) {}

export class TextInputWidget extends ResponseWidget<{
  text: string
  placeholder?: string
}>()('text_input', {
  placeholder: '',
}) {}


type Option = {
  value: string
  text: string
}

export class DropdownWidget extends ResponseWidget<{
  text: string
  options: Option[]
  placeholder?: string
}>()('dropdown', {
  placeholder: 'default_dropdown_placeholder',
}) {}

export class SliderWidget extends ResponseWidget<{
  text: string
  min?: number
  max?: number
  start?: number
  tooltip?: boolean
  minLabel?: string
  maxLabel?: string
}>()('slider', {
  min: 0,
  max: 100,
  start: 50,
  tooltip: false,
  minLabel: '0',
  maxLabel: '100',
}) {}

export class StepperWidget extends ContentWidget<{}>()('stepper') {}

export type SimpleCondition = 'lt' | 'gt' | 'lte' | 'gte' | 'eq' | 'neq'
export type BranchCondition =
  | SimpleCondition
  | 'includes'
  | `length-${SimpleCondition}`

export class ConditionalWidget extends ControlWidget<{
  condition: BranchCondition
  dataKey: string
  value: string | boolean | number
  widget: LayoutWidgetComponent | ResponseWidgetComponent
}>()('conditional') {}

export type LayoutWidgetComponent =
  | ButtonWidget
  | RichTextWidget
  | StepperWidget

export type ResponseWidgetComponent =
  | TextAreaWidget
  | TextInputWidget
  | DropdownWidget
  | SliderWidget

export type WidgetComponent =
  | LayoutWidgetComponent
  | ResponseWidgetComponent
  | ConditionalWidget
