/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme } from "@mui/material/styles";
import React from "react";

export type TextVariant =
  | "h1"
  | "h2"
  | "large"
  | "body"
  | "small"
  | "annotation"
  | "text"
  | "button"
  | "error"
  | "label";

export type MarginVariant = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

declare module "@mui/material/styles" {
  interface TypographyVariants
    extends Record<TextVariant, React.CSSProperties> {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions
    extends Partial<Record<TextVariant, React.CSSProperties>> {}

  interface ThemeOptions {
    margin: Record<MarginVariant, string>;
  }

  interface Theme {
    margin: Record<MarginVariant, string>;
  }

  interface PaletteOptions {
    lightGrey: string;
    darkGrey: string;
    white: string;
    black: string;
  }

  interface Palette {
    lightGrey: string;
    darkGrey: string;
    white: string;
    black: string;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides extends Record<TextVariant, true> {
    body1: false;
    body2: false;
    caption: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;
    inherit: false;
  }
}

const margin = {
  xs: "4px",
  sm: "8px",
  md: "16px",
  lg: "32px",
  xl: "40px",
  xxl: "64px",
} as const;

const lightPalette = {
  mode: "light",
  primary: {
    main: "#7fbfa3",
    light: "#8cf1cd",
    dark: "#6da389",
  },
  grey: {
    50: "#fafafa",
    200: "#d9d9e0",
    400: "#acabba",
    600: "#87869c",
    700: "#707089",
    900: "#3a3946",
  },
  error: {
    main: "#fd6370",
  },
  lightGrey: "#D9D9D9",
  darkGrey: "#979797",
  white: "#ffffff",
  black: "#1d1d1d",
} as const;

const theme = createTheme({
  typography: (palette) => ({
    fontFamily: "'Gotham', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    allVariants: {
      fontWeight: 400,
    },
    h1: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "120%",
      color: palette.black,
      marginBottom: margin.md,
      marginTop: margin.lg,
      display: "block",
    },
    h2: {
      fontSize: "24px",
      lineHeight: "120%",
      color: palette.black,
      marginBottom: margin.sm,
      display: "block",
    },
    label: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 700,
      marginBottom: margin.md,
      display: "block",
    },
    large: {
      fontSize: "24px",
      lineHeight: "140%",
      color: palette.black,
      marginBottom: margin.sm,
      display: "block",
    },
    body: {
      fontSize: "18px",
      lineHeight: "160%",
      color: palette.black,
      marginBottom: margin.lg,
      display: "block",
    },
    small: {
      fontSize: "14px",
      lineHeight: "140%",
      color: palette.grey["600"],
      marginBottom: margin.md,
      display: "block",
    },
    annotation: {
      fontSize: "12px",
      lineHeight: "120%",
      textTransform: "uppercase",
      color: palette.grey["600"],
      marginBottom: margin.md,
      display: "block",
    },
    text: {
      fontSize: "18px",
      lineHeight: "140%",
      color: palette.black,
      display: "block",
    },
    button: {
      fontSize: "16px",
      lineHeight: "100%",
      color: palette.black,
      textTransform: "uppercase",
      display: "block",
    },
    error: {
      fontSize: "14px",
      color: palette.error.main,
      lineHeight: "140%",
      display: "block",
    },
    body1: undefined,
    body2: undefined,
    caption: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    overline: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
  }),
  margin,
  palette: lightPalette,
});

export default theme;
