const StepContainer = (props: { children: React.ReactElement }) => {
  return (
    <div className="step-container" style={{ zIndex: 999 }}>
      <div
        style={{
          width: "100%",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <svg
          width="60"
          height="60"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 600 600"
          xmlSpace="preserve"
        >
          <g>
            <path
              fill={"#1d1d1d"}
              d="M356.52,118.94l-20.76-11.98c-1.62-0.94-1.73-3.24-0.2-4.32l20.76-14.68c1.7-1.2,4.05,0.01,4.05,2.1v26.66   C360.37,118.69,358.23,119.93,356.52,118.94z"
            />
            <path
              fill={"#1d1d1d"}
              d="M241.95,118.66l20.76-11.98c1.62-0.94,1.73-3.24,0.2-4.32l-20.76-14.68c-1.7-1.2-4.05,0.01-4.05,2.1v26.66   C238.1,118.41,240.24,119.65,241.95,118.66z"
            />
            <path
              fill={"#1d1d1d"}
              d="M393.48,326.46l-120.24-69.42v-18.49l19.58,11.31c1.72,0.99,3.63,1.48,5.54,1.48c1.91,0,3.83-0.49,5.54-1.48   l54.52-31.48c3.43-1.98,5.54-5.64,5.54-9.6v-62.96c0-3.96-2.11-7.62-5.54-9.6l-54.52-31.48c-3.43-1.98-7.65-1.98-11.09,0   l-54.52,31.48c-3.43,1.98-5.54,5.64-5.54,9.6v62.96c0,3.96,2.11,7.62,5.54,9.6l10.46,6.04v39.69c0,4.37,2.33,8.41,6.12,10.6   l120.24,69.42v88.63l-76.76,44.31l-76.76-44.31v-88.63l33.3-19.24c3.01-1.74,3-6.07,0-7.81l-14.43-8.33   c-2.03-1.17-4.52-1.17-6.55,0l-30.69,17.72c-3.79,2.19-6.12,6.23-6.12,10.6v102.77c0,4.37,2.33,8.41,6.12,10.6l89,51.38   c1.89,1.09,4.01,1.64,6.12,1.64c2.11,0,4.23-0.55,6.12-1.64l89-51.38c3.79-2.19,6.12-6.23,6.12-10.6V337.06   C399.6,332.68,397.27,328.64,393.48,326.46z M254.92,152.21l43.44-25.08l43.44,25.08v50.16l-43.44,25.08l-43.44-25.08V152.21z"
            />
          </g>
        </svg>
      </div>
      {props.children}
    </div>
  );
};

export default StepContainer;
